var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "p-0",
            class: {
              "container-layout": _vm.$screen.width >= 992,
              "container-mobile": _vm.$screen.width < 992,
            },
          },
          [
            _c(
              "b-row",
              { staticClass: "form" },
              [
                _c("b-col", { attrs: { cols: "12" } }, [
                  _c(
                    "h1",
                    {
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        ,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(235, "Imports")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-card",
                  { staticClass: "w-100" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                          [
                            _c(
                              "b-card",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(10, "Import type:"),
                                      "label-for": "importType",
                                      "label-cols": 4,
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.isDev
                                          ? _vm.optionsForDevImports
                                          : _vm.optionsForImports,
                                        size: "md",
                                        id: "exportType",
                                      },
                                      model: {
                                        value: _vm.importType,
                                        callback: function ($$v) {
                                          _vm.importType = $$v
                                        },
                                        expression: "importType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.showMovieMagicFile
                                  ? _c(
                                      "b-card",
                                      { staticClass: "w-100" },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                5020,
                                                "File to import"
                                              ),
                                              "label-for":
                                                "accConfirmationFileBrowse",
                                              "label-cols": 12,
                                            },
                                          },
                                          [
                                            _vm.importType === 2
                                              ? _c("b-form-file", {
                                                  ref: "pdfConfirmationFileId",
                                                  attrs: {
                                                    "browse-text": _vm.FormMSG(
                                                      113,
                                                      "Browse"
                                                    ),
                                                    name: "test",
                                                    id: "accConfirmationFileBrowse",
                                                    accept:
                                                      _vm.supportedMimeTypes,
                                                    placeholder: _vm.FormMSG(
                                                      51,
                                                      "Choose a file or drop it here..."
                                                    ),
                                                    "drop-placeholder":
                                                      _vm.FormMSG(
                                                        52,
                                                        "Drop file here..."
                                                      ),
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.submitConfirmationFile,
                                                  },
                                                  model: {
                                                    value: _vm.pdfFile,
                                                    callback: function ($$v) {
                                                      _vm.pdfFile = $$v
                                                    },
                                                    expression: "pdfFile",
                                                  },
                                                })
                                              : _c("b-form-file", {
                                                  ref: "pdfConfirmationFileId",
                                                  attrs: {
                                                    "browse-text": _vm.FormMSG(
                                                      113,
                                                      "Browse"
                                                    ),
                                                    name: "test",
                                                    id: "accConfirmationFileBrowse",
                                                    accept:
                                                      _vm.supportedMimeTypes,
                                                    placeholder: _vm.FormMSG(
                                                      51,
                                                      "Choose a file or drop it here..."
                                                    ),
                                                    "drop-placeholder":
                                                      _vm.FormMSG(
                                                        52,
                                                        "Drop file here..."
                                                      ),
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.submitConfirmationFile,
                                                  },
                                                  model: {
                                                    value: _vm.pdfFile,
                                                    callback: function ($$v) {
                                                      _vm.pdfFile = $$v
                                                    },
                                                    expression: "pdfFile",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                5021,
                                                "Filename"
                                              ),
                                              "label-for": "docFileName",
                                              "label-cols": 12,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                                id: "docFileName",
                                              },
                                              model: {
                                                value: _vm.fileName,
                                                callback: function ($$v) {
                                                  _vm.fileName = $$v
                                                },
                                                expression: "fileName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        !_vm.isDev ||
                                        (_vm.isDev && _vm.importType <= 2) ||
                                        _vm.importType === 7
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.eraseDepartmentBeforeImport,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.eraseDepartmentBeforeImport =
                                                              $$v
                                                          },
                                                          expression:
                                                            "eraseDepartmentBeforeImport",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                5023,
                                                                "Erase all unused departments and functions before import"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isDev ||
                                        (_vm.isDev && _vm.importType <= 2) ||
                                        _vm.importType === 7
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll-clean",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.eraseCategoryBeforeImport,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.eraseCategoryBeforeImport =
                                                              $$v
                                                          },
                                                          expression:
                                                            "eraseCategoryBeforeImport",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                65,
                                                                "Cleaning categories"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isDev ||
                                        (_vm.isDev && _vm.importType <= 2) ||
                                        _vm.importType === 7
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll1",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.importEmptyAccountDetails,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.importEmptyAccountDetails =
                                                              $$v
                                                          },
                                                          expression:
                                                            "importEmptyAccountDetails",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                5024,
                                                                "Import empty detail account"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.importType < 2 ||
                                        _vm.importType === 7
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll2",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.accountDetailsWith3NumbersAsGroupingCategory,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.accountDetailsWith3NumbersAsGroupingCategory =
                                                              $$v
                                                          },
                                                          expression:
                                                            "accountDetailsWith3NumbersAsGroupingCategory",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                5025,
                                                                "Import account detail with 3 number as grouping category"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isDev ||
                                        (_vm.isDev && _vm.importType <= 2) ||
                                        _vm.importType === 7
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll3",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.createDepartmentsAndFunctions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.createDepartmentsAndFunctions =
                                                              $$v
                                                          },
                                                          expression:
                                                            "createDepartmentsAndFunctions",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                5026,
                                                                "Create departments and functions"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.createDepartmentsAndFunctions
                                          ? _c(
                                              "b-col",
                                              {
                                                staticClass: "mt-3",
                                                attrs: { xl: "12" },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      [
                                                        _vm.importType < 2
                                                          ? _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "md",
                                                                  id: "disAll4",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.specifyCategoriesToCreate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.specifyCategoriesToCreate =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "specifyCategoriesToCreate",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      this.FormMSG(
                                                                        5027,
                                                                        "Create functions only for the following Movie Magic categories"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "b-form-checkbox",
                                                              {
                                                                attrs: {
                                                                  size: "md",
                                                                  id: "disAll4",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.specifyCategoriesToCreate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.specifyCategoriesToCreate =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "specifyCategoriesToCreate",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      this.FormMSG(
                                                                        5029,
                                                                        "Create functions only for the following Xotis categories"
                                                                      )
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            disabled:
                                                              !_vm.specifyCategoriesToCreate,
                                                            options:
                                                              _vm.categorieList,
                                                            taggable: true,
                                                            multiple: true,
                                                            "push-tags": "",
                                                          },
                                                          on: {
                                                            search: (
                                                              search,
                                                              loading
                                                            ) => {
                                                              _vm.test(search)
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.listOfCategoriesToCreateFunctionForAccountDetails,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.listOfCategoriesToCreateFunctionForAccountDetails =
                                                                $$v
                                                            },
                                                            expression:
                                                              "listOfCategoriesToCreateFunctionForAccountDetails",
                                                          },
                                                        }),
                                                        _c("small", {}, [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                50555,
                                                                "Press enter to validate a value"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.listOfCategoriesToCreateFunctionForAccountDetails.toString()
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showXLSFile
                                  ? _c(
                                      "b-card",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                5020,
                                                "File to import"
                                              ),
                                              "label-for":
                                                "accConfirmationFileBrowse",
                                              "label-cols": 12,
                                            },
                                          },
                                          [
                                            _c("b-form-file", {
                                              ref: "pdfConfirmationFileId",
                                              attrs: {
                                                "browse-text": _vm.FormMSG(
                                                  113,
                                                  "Browse"
                                                ),
                                                name: "test",
                                                id: "accConfirmationFileBrowse",
                                                accept: _vm.supportedMimeTypes,
                                                placeholder: _vm.FormMSG(
                                                  51,
                                                  "Choose a file or drop it here..."
                                                ),
                                                "drop-placeholder": _vm.FormMSG(
                                                  52,
                                                  "Drop file here..."
                                                ),
                                              },
                                              on: {
                                                change:
                                                  _vm.submitConfirmationFile,
                                              },
                                              model: {
                                                value: _vm.pdfFile,
                                                callback: function ($$v) {
                                                  _vm.pdfFile = $$v
                                                },
                                                expression: "pdfFile",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                5021,
                                                "Filename"
                                              ),
                                              "label-for": "docFileName",
                                              "label-cols": 12,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                                id: "docFileName",
                                              },
                                              model: {
                                                value: _vm.fileName,
                                                callback: function ($$v) {
                                                  _vm.fileName = $$v
                                                },
                                                expression: "fileName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        !_vm.isDev ||
                                        (_vm.isDev && _vm.importType <= 2) ||
                                        _vm.importType === 7 ||
                                        _vm.importType === 8
                                          ? _c(
                                              "b-col",
                                              { attrs: { xl: "12" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.eraseDepartmentBeforeImport,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.eraseDepartmentBeforeImport =
                                                              $$v
                                                          },
                                                          expression:
                                                            "eraseDepartmentBeforeImport",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                5023,
                                                                "Erase all unused departments and functions before import"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  { staticClass: "lh-20" },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          size: "md",
                                                          id: "disAll-clean",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.eraseCategoryBeforeImport,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.eraseCategoryBeforeImport =
                                                              $$v
                                                          },
                                                          expression:
                                                            "eraseCategoryBeforeImport",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              this.FormMSG(
                                                                65,
                                                                "Cleaning categories"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showDates
                                  ? _c(
                                      "b-card",
                                      [
                                        this.initDates
                                          ? _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: _vm.FormMSG(
                                                    11,
                                                    "From:"
                                                  ),
                                                  "label-for": "from",
                                                  "label-cols": 4,
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "date",
                                                    id: "from",
                                                  },
                                                  model: {
                                                    value: _vm.from,
                                                    callback: function ($$v) {
                                                      _vm.from = $$v
                                                    },
                                                    expression: "from",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(12, "To:"),
                                              "label-for": "to",
                                              "label-cols": 4,
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "date", id: "to" },
                                              model: {
                                                value: _vm.to,
                                                callback: function ($$v) {
                                                  _vm.to = $$v
                                                },
                                                expression: "to",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-content-end" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "12" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "primary",
                                                  disabled: _vm.isLoading,
                                                },
                                                on: {
                                                  click: _vm.processImport,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      this.FormMSG(
                                                        20,
                                                        "Start import process"
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                                _vm.isLoading
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "ml-2" },
                                                      [
                                                        _c("b-spinner", {
                                                          staticStyle: {
                                                            width: "1.25rem",
                                                            height: "1.25rem",
                                                          },
                                                          attrs: {
                                                            variant: "warning",
                                                            label:
                                                              "loading ...",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-success",
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          title: _vm.FormMSG(60, "Success!"),
                          "ok-variant": "success",
                          "ok-only": "",
                        },
                        on: {
                          ok: function ($event) {
                            _vm.successModal = false
                          },
                        },
                        model: {
                          value: _vm.successModal,
                          callback: function ($$v) {
                            _vm.successModal = $$v
                          },
                          expression: "successModal",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              this.FormMSG(
                                61,
                                "Your data has been imported with success."
                              )
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-error",
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          title: _vm.FormMSG(62, "Error!"),
                          "ok-variant": "success",
                          "ok-only": "",
                        },
                        on: {
                          ok: function ($event) {
                            _vm.errorModal = false
                          },
                        },
                        model: {
                          value: _vm.errorModal,
                          callback: function ($$v) {
                            _vm.errorModal = $$v
                          },
                          expression: "errorModal",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(this.errorMessage) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          "header-class": "header-class-modal-doc-package",
                          size: "lg",
                          "ok-only": "",
                          "ok-title-html": "",
                          title: _vm.FormMSG(64, "Log"),
                          "ok-title": _vm.FormMSG(63, "Close"),
                          "no-close-on-backdrop": "",
                        },
                        on: {
                          ok: _vm.handleCloseLogWindow,
                          cancel: _vm.handleCloseLogWindow,
                          hidden: _vm.handleCloseLogWindow,
                        },
                        model: {
                          value: _vm.openLog,
                          callback: function ($$v) {
                            _vm.openLog = $$v
                          },
                          expression: "openLog",
                        },
                      },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c("b-card-text", {
                              staticClass: "px-3 pb-3",
                              domProps: { innerHTML: _vm._s(_vm.importLog) },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("script-export-modal", {
                      attrs: {
                        items: _vm.scripts,
                        custom: _vm.customReport,
                        "export-type": _vm.exportTypeStr,
                      },
                      on: {
                        "script-export-modal:loading": _vm.handleScriptLoading,
                        "script-export-modal:closed":
                          _vm.handleScriptRerpotOnClosed,
                      },
                      model: {
                        value: _vm.isScriptReportOpen,
                        callback: function ($$v) {
                          _vm.isScriptReportOpen = $$v
                        },
                        expression: "isScriptReportOpen",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }